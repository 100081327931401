import { createGlobalStyle } from 'styled-components'

export const FONT_FAMILY_HEADLINE = 'Aeonik Pro, sans-serif'

export const FONT_FAMILY_BODY = 'Inter, sans-serif'

export const LAYOUT_MAX_WIDTH = [1000, null, null, null, null, 1432] as const

const TEXT_STYLE_PRIMARY = {
  fontFamily: FONT_FAMILY_BODY,
  fontWeight: 400,
  fontSize: ['1rem', null, null, null, null, '1.25rem'],
  lineHeight: ['1.5rem', null, null, null, null, '2rem'],
  letterSpacing: ['-0.01rem', null, null, null, null, '-0.0125rem'],
}

// TODO: REVPI-28 Migrate LandingPage to standard UI Kit
export const textStyles = {
  h0: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 900,
    fontSize: ['3rem', null, '6.5rem', null, null, '8.5rem'],
    lineHeight: 1,
    letterSpacing: ['-0.06rem', null, '-0.13rem', null, null, '-0.17rem'],
    textTransform: 'uppercase',
  },
  h1: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 900,
    fontSize: ['2.5rem', null, '5rem', null, null, '6.5rem'],
    lineHeight: 1,
    letterSpacing: ['-0.0375rem', null, '-0.075rem', null, null, '-0.0975rem'],
    textTransform: 'uppercase',
  },
  h2: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 900,
    fontSize: ['2.25rem', null, '3.75rem', null, null, '5rem'],
    lineHeight: 1,
    letterSpacing: ['-0.0225rem', null, '-0.0375rem', null, null, '-0.05rem'],
    textTransform: 'uppercase',
  },
  h3: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 900,
    fontSize: ['2rem', null, '3rem', null, null, '3.75rem'],
    lineHeight: 1,
    letterSpacing: ['-0.02rem', null, '-0.03rem', null, null, '-0.0375rem'],
    textTransform: 'uppercase',
  },
  h4: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 500,
    fontSize: ['1.75rem', null, null, '2.5rem', null, '3rem'],
    lineHeight: ['2rem', null, null, '3rem', null, '3.625rem'],
    letterSpacing: ['-0.0175rem', null, null, '-0.025rem', null, '-0.03rem'],
  },
  h5: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 500,
    fontSize: ['1.5rem', null, null, '2rem', null, '2.5rem'],
    lineHeight: ['1.75rem', null, null, '2.375rem', null, '3rem'],
    letterSpacing: ['-0.015rem', null, null, '-0.02rem', null, '-0.025rem'],
  },
  h6: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 500,
    fontSize: ['1.375rem', null, null, '1.5rem', null, '2rem'],
    lineHeight: ['1.625rem', null, null, '1.75rem', null, '2.375rem'],
    letterSpacing: ['-0.01375rem', null, null, '-0.015rem', null, '-0.02rem'],
  },
  subtitle1: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 400,
    fontSize: ['1.125rem', null, null, null, null, '1.5rem'],
    lineHeight: ['1.5rem', null, null, null, null, '2rem'],
  },
  subtitle2: {
    fontFamily: FONT_FAMILY_HEADLINE,
    fontWeight: 500,
    fontSize: ['1rem', null, null, null, null, '1.25rem'],
    lineHeight: ['1.375rem', null, null, null, null, '1.75rem'],
  },
  // `Body 1` in Figma
  primary: TEXT_STYLE_PRIMARY,
  input: TEXT_STYLE_PRIMARY,
  // `Body 2` in Figma typography
  secondary: {
    fontFamily: FONT_FAMILY_BODY,
    fontWeight: 400,
    fontSize: ['0.875rem', null, null, null, null, '1.125rem'],
    lineHeight: ['1.375rem', null, null, null, null, '1.75rem'],
    letterSpacing: ['-0.00438rem', null, null, null, null, '-0.00563rem'],
  },
  // `Caption 1` in Figma typography
  caption: {
    fontFamily: FONT_FAMILY_BODY,
    fontWeight: 600,
    fontSize: ['0.75rem', null, null, null, null, '1rem'],
    lineHeight: ['1.125rem', null, null, null, null, '1.5rem'],
    letterSpacing: ['0.0075rem', null, null, null, null, '0.01rem'],
  },
  // `Caption 2` in Figma typography
  small: {
    fontFamily: FONT_FAMILY_BODY,
    fontWeight: 400,
    fontSize: ['0.75rem', null, null, null, null, '1rem'],
    lineHeight: ['1.125rem', null, null, null, null, '1.5rem'],
    letterSpacing: ['0.01125rem', null, null, null, null, '0.015rem'],
  },
}

const backgroundColor = '#191C1F'
export const contactUsBackgroundColor = '#161618'

export const GlobalStyle = createGlobalStyle<{ isContactUsForm?: boolean }>`
  @font-face {
    font-family: 'Aeonik Pro';
    font-display: fallback;
    font-weight: 900;
    src: url('https://cdn.revolut.com/media/fonts/AeonikPro-Black.woff2') format("woff2");
  }

  @font-face {
    font-family: 'Aeonik Pro';
    font-display: fallback;
    font-weight: 500;
    src: url('https://cdn.revolut.com/media/fonts/AeonikPro-Medium.woff2') format("woff2");
  }

  @font-face {
    font-family: 'Aeonik Pro';
    font-display: fallback;
    font-weight: 400;
    src: url('https://cdn.revolut.com/media/fonts/AeonikPro-Regular.woff2') format("woff2");
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  html {
      background-color: ${props =>
        props.isContactUsForm ? contactUsBackgroundColor : backgroundColor} !important;
  }
  
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #FFFFFF;
    background: ${props =>
      props.isContactUsForm ? contactUsBackgroundColor : backgroundColor};
    margin: 0;
    min-height: 100%;
    padding: 0;
    font-family: 'Aeonik Pro', sans-serif;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
`

import React, { useMemo } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Item,
  ItemSkeleton,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { SkillPreview } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillPreview'
import { SkillSidebar } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillsSidebar'
import uniq from 'lodash/uniq'
import { isInterviewFeedbackSkillSection } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface AddSkillsProps {
  interviewScorecardPreview?: InterviewFeedbackInterface
  loading: boolean
  onRefresh: () => Promise<void>
}

export const AddSkills = ({
  interviewScorecardPreview,
  loading,
  onRefresh,
}: AddSkillsProps) => {
  const { values, submit } = useLapeContext<InterviewScorecardTemplateInterface>()
  const [openSkillsSidebar, toggleOpenSkillsSidebar] = useToggle()
  const showStatusPopup = useShowStatusPopup()

  const { skillSections, skillIds } = useMemo(() => {
    const sections = (interviewScorecardPreview?.scorecard?.sections || []).filter(
      isInterviewFeedbackSkillSection,
    )
    return {
      skillSections: sections,
      skillIds: uniq(
        sections.flatMap(section => section.skills?.flatMap(skill => skill.id)),
      ),
    }
  }, [interviewScorecardPreview])

  if (loading) {
    return (
      <Widget mb="s-24">
        <ItemSkeleton />
      </Widget>
    )
  }

  const handleDeleteSkill = async (internalDataId: string) => {
    if (values.sections) {
      const oldSections = values.sections
      try {
        showStatusPopup({
          preventUserClose: true,
          status: 'pending',
          title: 'Removing skill',
        })
        values.sections = values.sections.filter(
          ({ id }) => !internalDataId.includes(String(id)),
        )
        await submit()
        await onRefresh()
        showStatusPopup({
          title: 'Updated hiring stages order',
        })
      } catch (e) {
        values.sections = oldSections
      }
    }
  }

  return (
    <>
      <Widget mb="s-24">
        <VStack gap="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Palette" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Skills</Item.Title>
            </Item.Content>
          </Item>
          {!!skillSections.length && (
            <VStack px="s-16" pb="s-16" gap="s-16">
              {skillSections.map(section => (
                <SkillPreview
                  key={section.internal_data_id}
                  section={section}
                  onDelete={() => handleDeleteSkill(section.internal_data_id)}
                />
              ))}
            </VStack>
          )}
          <Box px="s-16" pb="s-16">
            <ActionButton useIcon="Plus" onClick={() => toggleOpenSkillsSidebar()}>
              Add skills
            </ActionButton>
          </Box>
        </VStack>
      </Widget>
      {openSkillsSidebar && (
        <SkillSidebar
          skillIds={skillIds}
          onAfterSubmit={onRefresh}
          onClose={() => toggleOpenSkillsSidebar()}
        />
      )}
    </>
  )
}

import React, { useMemo, useState } from 'react'
import { InterviewFeedbackSkillSectionInterface } from '@src/interfaces/interviewTool'
import { Icon, IconButton, Item, TransitionCollapse, Widget } from '@revolut/ui-kit'
import ScorecardSection from '@src/components/ScorecardGeneral/ScorecardSection'
import { PerformanceReviewTypes, ReviewCategory } from '@src/interfaces/performance'
import { getCard } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/utils'

interface SkillPreviewProps {
  section: InterviewFeedbackSkillSectionInterface
  onDelete: () => Promise<void>
}

export const SkillPreview = ({ section, onDelete }: SkillPreviewProps) => {
  const [expanded, setExpanded] = useState(false)
  const card = useMemo(() => {
    return getCard(section)
  }, [section])
  return (
    <Widget>
      <Item
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <Item.Prefix>
          <Icon size={24} name={expanded ? 'ChevronUp' : 'ChevronDown'} />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>{section.title}</Item.Title>
        </Item.Content>
        <Item.Side>
          <IconButton
            data-testid={`Delete ${section.title}`}
            useIcon="Delete"
            onClick={e => {
              e.stopPropagation()
              onDelete()
            }}
          />
        </Item.Side>
      </Item>
      <TransitionCollapse in={expanded}>
        <ScorecardSection
          mt="s-0"
          mb="s-0"
          card={card}
          cardIndex={0}
          category={ReviewCategory.Performance}
          disabled
          hideCardTitle
          hideJustification
          hideResult
          noWrapWidget
          noWidgetMargin
          noWidgetPadding
          onChange={() => {}}
          onSetResultLoading={() => {}}
          skillId="0"
          type={PerformanceReviewTypes.interviewScorecard}
        />
      </TransitionCollapse>
    </Widget>
  )
}

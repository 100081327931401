import React, { useEffect } from 'react'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { BaseOption } from '@src/interfaces/selectors'
import { useInitialFilters } from './hooks/useInitialFilters'
import {
  goalsNameColumn,
  goalsOwnerColumn,
  goalsWeightColumn,
  metricUnitValueColumn,
  metricValuesColumn,
} from '@src/constants/columns/goals'
import { useTable } from '@src/components/Table/hooks'
import { GoalsStats, parentGoalsListTableRequests } from '@src/api/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  CascadeGoalInterface,
  GoalsInterface,
  ParentGoalsInterface,
} from '@src/interfaces/goals'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { getSelectCellConfig } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectCell'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { TableTypes } from '@src/interfaces/table'

interface Props {
  cycle?: BaseOption
  childObjectId: number
  childContentTypeId: number | null
  object_id?: number | null
  is_company?: boolean
  contentTypeFilter: FilterByInterface | null
}

const initialSort = [
  { sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC },
]

const disableRowSelection = (goal: ParentGoalsInterface): boolean => {
  return goal.is_already_cascaded || Boolean(goal.template_rule)
}

const ROWS = {
  isNotNested: true,
  disabled: disableRowSelection,
  cells: [
    {
      ...getSelectCellConfig({ disableRow: disableRowSelection }),
    },
    {
      ...goalsNameColumn(goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })),
      width: 200,
    },
    {
      ...goalsOwnerColumn,
      width: 75,
    },
    {
      ...goalsWeightColumn,
      width: 80,
    },
    { ...metricValuesColumn, width: 120 },
    { ...metricUnitValueColumn, width: 65 },
  ],
}

export const CascadeGoalsTable = ({
  cycle,
  object_id,
  is_company,
  contentTypeFilter,
  childObjectId,
  childContentTypeId,
}: Props) => {
  const { values } = useLapeContext<CascadeGoalInterface>()

  const filters = useInitialFilters({
    cycle,
    object_id,
    is_company,
    contentTypeFilter,
    childContentTypeId,
    childObjectId,
  })

  const table = useTable(parentGoalsListTableRequests, filters, initialSort)

  const onSelectChange = (data: SelectTableWrapperOnChangeData<GoalsInterface>) => {
    values.parent_goals = data.isAllSelected ? data.tableData : data.selectedRowsData
  }

  useEffect(() => {
    table.resetFiltersAndSorting(filters, initialSort)
  }, [cycle, object_id, is_company, contentTypeFilter])

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <SelectTableWrapper
          enabled
          filters={table.filterBy}
          tableData={table.data}
          onChange={onSelectChange}
          tableDataLength={table.data.length}
        >
          <AdjustableTable<ParentGoalsInterface, GoalsStats>
            name={TableNames.CascadeGoals}
            emptyState={<EmptyTableRaw title="No goals" />}
            row={ROWS}
            useWindowScroll
            {...table}
            type={TableTypes.Contained}
          />
        </SelectTableWrapper>
      </Table.Widget.Table>
    </Table.Widget>
  )
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import { skillRequests } from '@src/api/skills'
import { useTable } from '@src/components/TableV2/hooks'
import { SkillInterface } from '@src/interfaces/skills'
import { Button, chain, ItemSkeleton, Side, VStack } from '@revolut/ui-kit'
import { Virtuoso } from 'react-virtuoso'
import { SkillGroup } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillGroup'
import { SkillItem } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillItem'
import { SkillFunctionGroup } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/types'
import { SkillFilter } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillFilter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { getPrefilledSection } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/utils'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface SkillSidebarProps {
  skillIds: (number | string | undefined)[]
  onAfterSubmit: () => Promise<void>
  onClose: VoidFunction
}

export const SkillSidebar = ({ skillIds, onAfterSubmit, onClose }: SkillSidebarProps) => {
  const { values, submit } = useLapeContext<InterviewScorecardTemplateInterface>()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [selectedSkillIds, setSelectedSkillIds] = useState<(number | string)[]>([])
  const [loading, setLoading] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  const table = useTable<SkillInterface>(skillRequests, undefined, [
    {
      sortBy: 'function__name',
    },
  ])

  useEffect(() => {
    const currentSkillIds = table.data.map(({ id }) => id)
    setSelectedSkillIds(
      selectedSkillIds.filter(id => currentSkillIds.includes(Number(id))),
    )
  }, [table.data])

  const groupedByFunction = useMemo(() => {
    return Object.values(
      table.data.reduce<{ [key: string]: SkillFunctionGroup }>((acc, curr) => {
        const functionId = curr.function?.id
        const currFunction = acc[functionId] || {
          function: curr.function,
          skills: [],
        }
        return {
          ...acc,
          [functionId]: {
            ...currFunction,
            function: curr.function,
            skills: [...currFunction.skills, curr],
          },
        }
      }, {}),
    )
  }, [table.data])

  const handleSelectSkill = (skillId: number | string) => {
    const selected = selectedSkillIds.includes(skillId)
    setSelectedSkillIds(
      selected
        ? selectedSkillIds.filter(id => id !== skillId)
        : [...selectedSkillIds, skillId],
    )
  }

  const handleAddSkills = async () => {
    try {
      setLoading(true)
      const skills = table.data.filter(({ id }) => selectedSkillIds.includes(id))
      const promises = await Promise.allSettled(
        skills.map(skill => getPrefilledSection(skill)),
      )
      promises.forEach(res => {
        if (res.status === 'fulfilled') {
          values.sections = [...(values.sections || []), res.value]
        }
      })
      await submit()
      await onAfterSubmit()
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: 'There was a error adding skills',
        description: getMessageFromApiError(e),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <SideBar
      variant="wide"
      title="Add skill"
      isOpen
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      <VStack gap="s-16">
        <SkillFilter
          onChange={filterOptions => {
            table.onFilterChange({
              filters: filterOptions || [],
              columnName: 'function__id',
              disableQueryParam: true,
            })
          }}
        />
        <Virtuoso
          data={groupedByFunction}
          customScrollParent={scrollRef?.current || undefined}
          overscan={150}
          // https://github.com/petyosi/react-virtuoso/issues/341
          initialItemCount={table.data.length - 1}
          endReached={() => {
            if (table.count > table.data.length) {
              table.fetchNextPage()
            }
          }}
          itemContent={(_, skillGroup) => {
            return (
              <SkillGroup
                function={skillGroup.function}
                numberOfSkills={skillGroup.skills.length}
              >
                {skillGroup?.skills.map(skill => {
                  const disabled = skillIds.includes(skill.id)
                  const selected =
                    skillIds.includes(skill.id) || selectedSkillIds.includes(skill.id)
                  return (
                    <SkillItem
                      key={skill.id}
                      disabled={disabled}
                      selected={selected}
                      skill={skill}
                      onClick={() => {
                        handleSelectSkill(skill.id)
                      }}
                    />
                  )
                })}
              </SkillGroup>
            )
          }}
        />
        {table.loading && <ItemSkeleton />}
      </VStack>
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose} pending={loading}>
          Cancel
        </Button>
        <Button
          disabled={!selectedSkillIds.length}
          onClick={handleAddSkills}
          pending={loading}
        >
          {selectedSkillIds.length ? chain('Add', selectedSkillIds.length) : 'Add'}
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
